const content =
{
    city: "City",
    pressure: "Pressure",
    humidity: "Humidity",
    temperature:"Temperature",
    wind: "Wind",
    test18: [
        "", ""
    ],
    test19: "option selected",
    test20: {
        test21: {
            test22: ""
        },
        test23: {
            test24: ""
        },
        test25: {
            test26: "",
            test27: ``
        }
    },
    test28: [
        {
            test29: "",
            test30: ``,
            test31: [
                {
                    test32: "",
                    test33: ``,
                    value: "AV:N"
                }
            ]
        }
    ],
    test34: ""
};

export default content;